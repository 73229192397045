<script setup lang="ts">
    // Import the props object from "vue" to define props
    import { defineProps, computed } from "vue";

    // Define props using the defineProps function
    const props = defineProps({
        syncStepProgress: {
            type: Object,
            required: true,
        },
    });

    const percentage = (curr, max) => {
    return (100 * curr) / max;
    };

    const appsCompleted = computed(() => {
    return props.syncStepProgress.appsDownloaded === props.syncStepProgress.appsToDownload;
    });

    const resourcesCompleted = computed(() => {
    return props.syncStepProgress.webResourcesDownloaded === props.syncStepProgress.webResourcesToDownload;
    });

    const resourcesErrors = computed(() => {
    return props.syncStepProgress.webResourcesFailedToDownload > 0;
    });

    const appsErrors = computed(() => {
    return props.syncStepProgress.appsFailedToDownload > 0;
    });

    const appsStarted = computed(() => {
    return props.syncStepProgress.appsDownloaded > 1;
    });

    const resourcesStarted = computed(() => {
    return props.syncStepProgress.webResourcesDownloaded > 1;
    });

</script>

<script lang="ts">
export default {
  name: "AppProgressOffline",
};
</script>

<template>
  <div class="card shadow-sm mb-3 w-100">
    <div class="card-header d-flex justify-content-between">
        <div class="d-flex flex-column">
          <!-- Definition -->
          <div class="fw-bold">
            {{ props.syncStepProgress.title }}
          </div>
            <!-- TITLE -->
          <div class="small-subtitle">
              <span>App & Resources</span>
          </div>
        </div>
        <!-- STATUS -->
        <div class="d-flex justify-content-center">
          <template v-if="props.syncStepProgress._syncStatus !== 10">
            <span
              class="text-black d-flex align-items-center gap-1"
              v-if="!resourcesStarted && !appsStarted && !appsCompleted && !resourcesCompleted && !appsErrors && !resourcesErrors"
            >
              <i class="bi bi-pause-circle-fill text-black"></i> Waiting to start
              </span>
              <span
              class="text-success d-flex align-items-center gap-1"
              v-if="appsCompleted && resourcesCompleted"
            >
              <i class="bi bi-check-circle-fill text-success"></i> Completed
            </span>
            <span
              class="text-danger d-flex align-items-center gap-1"
              v-if="appsErrors || resourcesErrors"
            >
              <i class="bi bi-exclamation-circle-fill text-danger"></i> Error completing
            </span>
          </template>

          <template v-if="props.syncStepProgress._syncStatus === 10">
            <span
              class="text-danger d-flex align-items-center gap-1"
            >
              <i class="bi bi-x-octagon-fill text-danger"></i> Cancelled
            </span>
          </template>
      </div>
    </div>
    <div class="card-body">

  <template v-if="props.syncStepProgress.appsToDownload > 1">
        <div class="d-flex align-items-center justify-content-between gap-1">
          <div>
            <span>Apps: {{ props.syncStepProgress.appsDownloaded }}</span>
            <span> / </span>
            <span>{{ props.syncStepProgress.appsToDownload }}</span>
          </div>
          <div v-if="appsCompleted">
              <i class="bi bi-check-circle-fill text-success"></i>
          </div>
        </div>

        <!-- Stacked progress bar -->
        <div
          class="progress-stacked"
          :style="{
            height:
              props.syncStepProgress.appsDownloaded + props.syncStepProgress.appsFailedToDownload === props.syncStepProgress.appsToDownload
                ? '5px'
                : '15px',
          }"
        >
          <div
            class="progress"
            role="progressbar"
            :aria-valuenow="percentage(props.syncStepProgress.appsDownloaded, props.syncStepProgress.appsToDownload)"
            aria-valuemin="0"
            aria-valuemax="100"
            :style="{ width: percentage(props.syncStepProgress.appsDownloaded, props.syncStepProgress.appsToDownload) + '%' }"
          >
            <div
              v-if="props.syncStepProgress.appsDownloaded + props.syncStepProgress.appsFailedToDownload === props.syncStepProgress.appsToDownload"
              class="progress-bar bg-primary"
            ></div>
            <div v-else class="progress-bar progress-bar-striped progress-bar-animated bg-primary"></div>
          </div>
          <div
            class="progress"
            role="progressbar"
            :aria-valuenow="percentage(props.syncStepProgress.appsFailedToDownload, props.syncStepProgress.appsToDownload)"
            aria-valuemin="0"
            aria-valuemax="100"
            :style="{ width: percentage(props.syncStepProgress.appsFailedToDownload, props.syncStepProgress.appsToDownload) + '%' }"
          >
            <div
              v-if="props.syncStepProgress.appsDownloaded + props.syncStepProgress.appsFailedToDownload === props.syncStepProgress.appsToDownload"
              class="progress-bar bg-danger"
            ></div>
            <div v-else class="progress-bar progress-bar-striped progress-bar-animated bg-danger"></div>
          </div>
        </div>
  </template>
      <!-- Failed apps -->
      <p v-if="appsErrors">{{ props.syncStepProgress.appsFailedToDownload }} Apps failed to download</p>

      <!-- Resources download -->
      <div class="d-flex align-items-center justify-content-between gap-1">
        <div>
          <span>Resources: {{ props.syncStepProgress.webResourcesDownloaded }}</span>
          <span> / </span>
          <span>{{ props.syncStepProgress.webResourcesToDownload }}</span>
        </div>
        <div v-if="resourcesCompleted">
            <i class="bi bi-check-circle-fill text-success"></i>
        </div>
      </div>

      <!-- Stacked progress bar -->
      <div
        class="progress-stacked"
        :style="{
          height:
            props.syncStepProgress.webResourcesDownloaded + props.syncStepProgress.webResourcesFailedToDownload ===
            props.syncStepProgress.webResourcesToDownload
              ? '5px'
              : '15px',
        }"
      >
        <!-- Downloaded -->
        <div
          class="progress"
          role="progressbar"
          :aria-valuenow="percentage(props.syncStepProgress.webResourcesDownloaded, props.syncStepProgress.webResourcesToDownload)"
          aria-valuemin="0"
          aria-valuemax="100"
          :style="{ width: percentage(props.syncStepProgress.webResourcesDownloaded, props.syncStepProgress.webResourcesToDownload) + '%' }"
        >
          <div
            v-if="
              props.syncStepProgress.webResourcesDownloaded + props.syncStepProgress.webResourcesFailedToDownload ===
              props.syncStepProgress.webResourcesToDownload
            "
            class="progress-bar bg-primary"
          ></div>
          <div v-else class="progress-bar progress-bar-striped progress-bar-animated bg-primary"></div>
        </div>
        <!-- Failed -->
        <div
          class="progress"
          role="progressbar"
          :aria-valuenow="percentage(props.syncStepProgress.webResourcesDownloaded, props.syncStepProgress.webResourcesToDownload)"
          aria-valuemin="0"
          aria-valuemax="100"
          :style="{ width: percentage(props.syncStepProgress.webResourcesFailedToDownload, props.syncStepProgress.webResourcesToDownload) + '%' }"
        >
          <div
            v-if="
              props.syncStepProgress.webResourcesDownloaded + props.syncStepProgress.webResourcesFailedToDownload ===
              props.syncStepProgress.webResourcesToDownload
            "
            class="progress-bar bg-danger"
          ></div>
          <div v-else class="progress-bar progress-bar-striped progress-bar-animated bg-danger"></div>
        </div>
      </div>

      <!-- Failed resources -->
      <p v-if="resourcesErrors">
        {{ props.syncStepProgress.webResourcesFailedToDownload }} Resources failed to download
      </p>
    </div>
  </div>
</template>